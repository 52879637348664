import {uniq} from "@pg-mono/nodash";

import {generalRankingKeysType, generalRankings, rankingLastYear} from "../constants/ranking";

export const getRankingCitiesIdByYear = (year?: generalRankingKeysType) => {
    if (year) {
        return uniq(generalRankings[year].map(({regionId}) => regionId));
    }

    return uniq(generalRankings[rankingLastYear.toString() as generalRankingKeysType].map(({regionId}) => regionId));
};
import {uniq} from "@pg-mono/nodash";

import {generalRankingKeysType, generalRankings, rankingLastYear} from "../constants/ranking";

export const getRankingCitiesIdByYear = (year?: generalRankingKeysType) => {
    if (year) {
        return uniq(generalRankings[year].map(({regionId}) => regionId));
    }

    return uniq(generalRankings[rankingLastYear.toString() as generalRankingKeysType].map(({regionId}) => regionId));
};
