import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../app/api/rp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getRegionDetailListApi} from "../../region/api/get_region_detail_list";
import {getRegionListApi} from "../../region/api/get_region_list";
import {IAppDispatch} from "../../store/store";
import {setViewTypeAction} from "../../view_type/actions/set_view_type_action";
import {ViewType} from "../../view_type/ViewType";
import {getRankingPartners} from "../api/get_ranking_partners";
import {IRankingListingViewParams} from "../types/IRankingListingViewParams";
import {getRankingCitiesIdByYear} from "../utils/get_ranking_cities_ids";
import {updateRankingInfoMetaData} from "./update_ranking_meta_data";

export const rankingMainViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IRankingListingViewParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const cities = getRankingCitiesIdByYear();

        await dispatch(getRegionListApi.endpoints.getRegionList.initiate({region: cities}));
        await dispatch(getRegionDetailListApi.endpoints.getRegionDetailList.initiate({regionIds: cities}));
        await dispatch(getRankingPartners.endpoints.getRankingPartners.initiate());

        dispatch(updateRankingInfoMetaData(ctx));
        dispatch(setViewTypeAction(ViewType.OFFER_RANKING));

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
    };
import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../app/api/rp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getRegionDetailListApi} from "../../region/api/get_region_detail_list";
import {getRegionListApi} from "../../region/api/get_region_list";
import {IAppDispatch} from "../../store/store";
import {setViewTypeAction} from "../../view_type/actions/set_view_type_action";
import {ViewType} from "../../view_type/ViewType";
import {getRankingPartners} from "../api/get_ranking_partners";
import {IRankingListingViewParams} from "../types/IRankingListingViewParams";
import {getRankingCitiesIdByYear} from "../utils/get_ranking_cities_ids";
import {updateRankingInfoMetaData} from "./update_ranking_meta_data";

export const rankingMainViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IRankingListingViewParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const cities = getRankingCitiesIdByYear();

        await dispatch(getRegionListApi.endpoints.getRegionList.initiate({region: cities}));
        await dispatch(getRegionDetailListApi.endpoints.getRegionDetailList.initiate({regionIds: cities}));
        await dispatch(getRankingPartners.endpoints.getRankingPartners.initiate());

        dispatch(updateRankingInfoMetaData(ctx));
        dispatch(setViewTypeAction(ViewType.OFFER_RANKING));

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
    };
