import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {rpApi} from "../../app/api/rp_api";
import {BRAND_NAME} from "../../app/constants/company";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetch404Redirect} from "../../errors/actions/page_404_actions";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getOfferDetailList} from "../../offer/api/get_offer_detail_list";
import {getRegionDetailListApi} from "../../region/api/get_region_detail_list";
import {getRegionDetailListBySlug} from "../../region/api/get_region_detail_list_by_slug";
import {getRegionListApi} from "../../region/api/get_region_list";
import {IAppDispatch} from "../../store/store";
import {setViewTypeAction} from "../../view_type/actions/set_view_type_action";
import {ViewType} from "../../view_type/ViewType";
import {IRankingListingViewParams} from "../types/IRankingListingViewParams";
import {ISearchedRegion} from "../types/ISearchedRegion";
import {getRankingCitiesIdByYear} from "../utils/get_ranking_cities_ids";
import {getRankingItems} from "../utils/get_ranking_items";

export const rankingLastYearViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IRankingListingViewParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const {citySlug} = ctx.match.params;
        const searchedRegions = await dispatch(getRegionDetailListBySlug.endpoints.getRegionDetailListBySlug.initiate({slug: citySlug}));
        const currentRegion = searchedRegions?.data?.results.length ? searchedRegions.data.results[0] : null;

        // TODO rewrite to rtk?
        const redirectUrl = (await fetch404Redirect(ctx.route.pathname, ctx.meta)) ?? appendQueryString(rpAppLink.ranking.base(), ctx.route.query);

        if (!currentRegion) {
            dispatch(enable301ResponseState(redirectUrl));
            return false;
        }

        const cities = getRankingCitiesIdByYear();
        const foundRankingCity = cities.find((cityId) => cityId === currentRegion.id);

        if (!foundRankingCity) {
            dispatch(enable301ResponseState(redirectUrl));
            return false;
        }

        const rankingItems = getRankingItems(currentRegion.id);
        const offerIds = rankingItems ? rankingItems.map((item) => item.offerId) : [];
        await dispatch(getOfferDetailList.endpoints.getOfferDetailList.initiate({offerIds}));
        await dispatch(getRegionListApi.endpoints.getRegionList.initiate({region: cities}));
        await dispatch(getRegionDetailListApi.endpoints.getRegionDetailList.initiate({regionIds: cities}));

        dispatch(setViewTypeAction(ViewType.OFFER_RANKING));
        dispatch(updateRankingListMetaDataAction(currentRegion, ctx.route.pathname));

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
    };

function updateRankingListMetaDataAction(region: ISearchedRegion, pathname: string) {
    const title = `Ranking Inwestycji deweloperskich ${region.name_declension_where} - ${BRAND_NAME}`;
    const description = `Ranking najlepszych inwestycji ${region.name_declension_where}. Zobacz najpopularniejsze inwestycje według użytkowników portalu ${BRAND_NAME}!`;

    return updateMetaData({title, description, pathname: pathname});
}
import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {rpApi} from "../../app/api/rp_api";
import {BRAND_NAME} from "../../app/constants/company";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetch404Redirect} from "../../errors/actions/page_404_actions";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getOfferDetailList} from "../../offer/api/get_offer_detail_list";
import {getRegionDetailListApi} from "../../region/api/get_region_detail_list";
import {getRegionDetailListBySlug} from "../../region/api/get_region_detail_list_by_slug";
import {getRegionListApi} from "../../region/api/get_region_list";
import {IAppDispatch} from "../../store/store";
import {setViewTypeAction} from "../../view_type/actions/set_view_type_action";
import {ViewType} from "../../view_type/ViewType";
import {IRankingListingViewParams} from "../types/IRankingListingViewParams";
import {ISearchedRegion} from "../types/ISearchedRegion";
import {getRankingCitiesIdByYear} from "../utils/get_ranking_cities_ids";
import {getRankingItems} from "../utils/get_ranking_items";

export const rankingLastYearViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IRankingListingViewParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const {citySlug} = ctx.match.params;
        const searchedRegions = await dispatch(getRegionDetailListBySlug.endpoints.getRegionDetailListBySlug.initiate({slug: citySlug}));
        const currentRegion = searchedRegions?.data?.results.length ? searchedRegions.data.results[0] : null;

        // TODO rewrite to rtk?
        const redirectUrl = (await fetch404Redirect(ctx.route.pathname, ctx.meta)) ?? appendQueryString(rpAppLink.ranking.base(), ctx.route.query);

        if (!currentRegion) {
            dispatch(enable301ResponseState(redirectUrl));
            return false;
        }

        const cities = getRankingCitiesIdByYear();
        const foundRankingCity = cities.find((cityId) => cityId === currentRegion.id);

        if (!foundRankingCity) {
            dispatch(enable301ResponseState(redirectUrl));
            return false;
        }

        const rankingItems = getRankingItems(currentRegion.id);
        const offerIds = rankingItems ? rankingItems.map((item) => item.offerId) : [];
        await dispatch(getOfferDetailList.endpoints.getOfferDetailList.initiate({offerIds}));
        await dispatch(getRegionListApi.endpoints.getRegionList.initiate({region: cities}));
        await dispatch(getRegionDetailListApi.endpoints.getRegionDetailList.initiate({regionIds: cities}));

        dispatch(setViewTypeAction(ViewType.OFFER_RANKING));
        dispatch(updateRankingListMetaDataAction(currentRegion, ctx.route.pathname));

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
    };

function updateRankingListMetaDataAction(region: ISearchedRegion, pathname: string) {
    const title = `Ranking Inwestycji deweloperskich ${region.name_declension_where} - ${BRAND_NAME}`;
    const description = `Ranking najlepszych inwestycji ${region.name_declension_where}. Zobacz najpopularniejsze inwestycje według użytkowników portalu ${BRAND_NAME}!`;

    return updateMetaData({title, description, pathname: pathname});
}
