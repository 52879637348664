import {IRankingItem} from "../types/IRankingItem";

export const ranking2023: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 22550,
        offerId: 13899
    },
    {
        regionOrder: 2,
        regionId: 22550,
        offerId: 14633
    },
    {
        regionOrder: 3,
        regionId: 22550,
        offerId: 13818
    },
    {
        regionOrder: 4,
        regionId: 22550,
        offerId: 12148
    },
    {
        regionOrder: 5,
        regionId: 22550,
        offerId: 11325
    },
    {
        regionOrder: 1,
        regionId: 46083,
        offerId: 13149
    },
    {
        regionOrder: 2,
        regionId: 46083,
        offerId: 12361
    },
    {
        regionOrder: 3,
        regionId: 46083,
        offerId: 14555
    },
    {
        regionOrder: 4,
        regionId: 46083,
        offerId: 14497
    },
    {
        regionOrder: 5,
        regionId: 46083,
        offerId: 14510
    },
    {
        regionOrder: 1,
        regionId: 31600,
        offerId: 14973
    },
    {
        regionOrder: 2,
        regionId: 31600,
        offerId: 15235
    },
    {
        regionOrder: 3,
        regionId: 31600,
        offerId: 14076
    },
    {
        regionOrder: 4,
        regionId: 31600,
        offerId: 12921
    },
    {
        regionOrder: 5,
        regionId: 31600,
        offerId: 11661
    },
    {
        regionOrder: 1,
        regionId: 11158,
        offerId: 15462
    },
    {
        regionOrder: 2,
        regionId: 11158,
        offerId: 13941
    },
    {
        regionOrder: 3,
        regionId: 11158,
        offerId: 14404
    },
    {
        regionOrder: 4,
        regionId: 11158,
        offerId: 15082
    },
    {
        regionOrder: 5,
        regionId: 11158,
        offerId: 13804
    },
    {
        regionOrder: 1,
        regionId: 13220,
        offerId: 13013
    },
    {
        regionOrder: 2,
        regionId: 13220,
        offerId: 15090
    },
    {
        regionOrder: 3,
        regionId: 13220,
        offerId: 14081
    },
    {
        regionOrder: 4,
        regionId: 13220,
        offerId: 14200
    },
    {
        regionOrder: 5,
        regionId: 13220,
        offerId: 13337
    },
    {
        regionOrder: 1,
        regionId: 16187,
        offerId: 15646
    },
    {
        regionOrder: 2,
        regionId: 16187,
        offerId: 16600
    },
    {
        regionOrder: 3,
        regionId: 16187,
        offerId: 13929
    },
    {
        regionOrder: 4,
        regionId: 16187,
        offerId: 13099
    },
    {
        regionOrder: 5,
        regionId: 16187,
        offerId: 14449
    },
    {
        regionOrder: 1,
        regionId: 30892,
        offerId: 15349
    },
    {
        regionOrder: 2,
        regionId: 30892,
        offerId: 14425
    },
    {
        regionOrder: 3,
        regionId: 30892,
        offerId: 14734
    },
    {
        regionOrder: 4,
        regionId: 30892,
        offerId: 13682
    },
    {
        regionOrder: 5,
        regionId: 30892,
        offerId: 13308
    },
    {
        regionOrder: 1,
        regionId: 32657,
        offerId: 13356
    },
    {
        regionOrder: 2,
        regionId: 32657,
        offerId: 15127
    },
    {
        regionOrder: 3,
        regionId: 32657,
        offerId: 12093
    },
    {
        regionOrder: 4,
        regionId: 32657,
        offerId: 12694
    },
    {
        regionOrder: 5,
        regionId: 32657,
        offerId: 14143
    },
    {
        regionOrder: 1,
        regionId: 8647,
        offerId: 13879
    },
    {
        regionOrder: 2,
        regionId: 8647,
        offerId: 14312
    },
    {
        regionOrder: 3,
        regionId: 8647,
        offerId: 13813
    },
    {
        regionOrder: 4,
        regionId: 8647,
        offerId: 14406
    },
    {
        regionOrder: 5,
        regionId: 8647,
        offerId: 13729
    },
    {
        regionOrder: 1,
        regionId: 26955,
        offerId: 15359
    },
    {
        regionOrder: 2,
        regionId: 26955,
        offerId: 15325
    },
    {
        regionOrder: 3,
        regionId: 26955,
        offerId: 15351
    },
    {
        regionOrder: 4,
        regionId: 26955,
        offerId: 16136
    },
    {
        regionOrder: 5,
        regionId: 26955,
        offerId: 15182
    }
];
import {IRankingItem} from "../types/IRankingItem";

export const ranking2023: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 22550,
        offerId: 13899
    },
    {
        regionOrder: 2,
        regionId: 22550,
        offerId: 14633
    },
    {
        regionOrder: 3,
        regionId: 22550,
        offerId: 13818
    },
    {
        regionOrder: 4,
        regionId: 22550,
        offerId: 12148
    },
    {
        regionOrder: 5,
        regionId: 22550,
        offerId: 11325
    },
    {
        regionOrder: 1,
        regionId: 46083,
        offerId: 13149
    },
    {
        regionOrder: 2,
        regionId: 46083,
        offerId: 12361
    },
    {
        regionOrder: 3,
        regionId: 46083,
        offerId: 14555
    },
    {
        regionOrder: 4,
        regionId: 46083,
        offerId: 14497
    },
    {
        regionOrder: 5,
        regionId: 46083,
        offerId: 14510
    },
    {
        regionOrder: 1,
        regionId: 31600,
        offerId: 14973
    },
    {
        regionOrder: 2,
        regionId: 31600,
        offerId: 15235
    },
    {
        regionOrder: 3,
        regionId: 31600,
        offerId: 14076
    },
    {
        regionOrder: 4,
        regionId: 31600,
        offerId: 12921
    },
    {
        regionOrder: 5,
        regionId: 31600,
        offerId: 11661
    },
    {
        regionOrder: 1,
        regionId: 11158,
        offerId: 15462
    },
    {
        regionOrder: 2,
        regionId: 11158,
        offerId: 13941
    },
    {
        regionOrder: 3,
        regionId: 11158,
        offerId: 14404
    },
    {
        regionOrder: 4,
        regionId: 11158,
        offerId: 15082
    },
    {
        regionOrder: 5,
        regionId: 11158,
        offerId: 13804
    },
    {
        regionOrder: 1,
        regionId: 13220,
        offerId: 13013
    },
    {
        regionOrder: 2,
        regionId: 13220,
        offerId: 15090
    },
    {
        regionOrder: 3,
        regionId: 13220,
        offerId: 14081
    },
    {
        regionOrder: 4,
        regionId: 13220,
        offerId: 14200
    },
    {
        regionOrder: 5,
        regionId: 13220,
        offerId: 13337
    },
    {
        regionOrder: 1,
        regionId: 16187,
        offerId: 15646
    },
    {
        regionOrder: 2,
        regionId: 16187,
        offerId: 16600
    },
    {
        regionOrder: 3,
        regionId: 16187,
        offerId: 13929
    },
    {
        regionOrder: 4,
        regionId: 16187,
        offerId: 13099
    },
    {
        regionOrder: 5,
        regionId: 16187,
        offerId: 14449
    },
    {
        regionOrder: 1,
        regionId: 30892,
        offerId: 15349
    },
    {
        regionOrder: 2,
        regionId: 30892,
        offerId: 14425
    },
    {
        regionOrder: 3,
        regionId: 30892,
        offerId: 14734
    },
    {
        regionOrder: 4,
        regionId: 30892,
        offerId: 13682
    },
    {
        regionOrder: 5,
        regionId: 30892,
        offerId: 13308
    },
    {
        regionOrder: 1,
        regionId: 32657,
        offerId: 13356
    },
    {
        regionOrder: 2,
        regionId: 32657,
        offerId: 15127
    },
    {
        regionOrder: 3,
        regionId: 32657,
        offerId: 12093
    },
    {
        regionOrder: 4,
        regionId: 32657,
        offerId: 12694
    },
    {
        regionOrder: 5,
        regionId: 32657,
        offerId: 14143
    },
    {
        regionOrder: 1,
        regionId: 8647,
        offerId: 13879
    },
    {
        regionOrder: 2,
        regionId: 8647,
        offerId: 14312
    },
    {
        regionOrder: 3,
        regionId: 8647,
        offerId: 13813
    },
    {
        regionOrder: 4,
        regionId: 8647,
        offerId: 14406
    },
    {
        regionOrder: 5,
        regionId: 8647,
        offerId: 13729
    },
    {
        regionOrder: 1,
        regionId: 26955,
        offerId: 15359
    },
    {
        regionOrder: 2,
        regionId: 26955,
        offerId: 15325
    },
    {
        regionOrder: 3,
        regionId: 26955,
        offerId: 15351
    },
    {
        regionOrder: 4,
        regionId: 26955,
        offerId: 16136
    },
    {
        regionOrder: 5,
        regionId: 26955,
        offerId: 15182
    }
];
