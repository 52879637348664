import {IRankingItem} from "../types/IRankingItem";

const bialystokRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 9692,
        offerId: 16165
    },
    {
        regionOrder: 2,
        regionId: 9692,
        offerId: 16568
    },
    {
        regionOrder: 3,
        regionId: 9692,
        offerId: 17584
    },
    {
        regionOrder: 4,
        regionId: 9692,
        offerId: 16265
    },
    {
        regionOrder: 5,
        regionId: 9692,
        offerId: 13522
    }
];
const bydgoszczRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 22550,
        offerId: 14601
    },
    {
        regionOrder: 2,
        regionId: 22550,
        offerId: 13911
    },
    {
        regionOrder: 3,
        regionId: 22550,
        offerId: 14633
    },
    {
        regionOrder: 4,
        regionId: 22550,
        offerId: 15663
    },
    {
        regionOrder: 5,
        regionId: 22550,
        offerId: 12148
    }
];
const gdanskRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 46083,
        offerId: 15119
    },
    {
        regionOrder: 2,
        regionId: 46083,
        offerId: 14542
    },
    {
        regionOrder: 3,
        regionId: 46083,
        offerId: 13149
    },
    {
        regionOrder: 4,
        regionId: 46083,
        offerId: 14738
    },
    {
        regionOrder: 5,
        regionId: 46083,
        offerId: 17388
    }
];
const gdyniaRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 6196,
        offerId: 14116
    },
    {
        regionOrder: 2,
        regionId: 6196,
        offerId: 15816
    },
    {
        regionOrder: 3,
        regionId: 6196,
        offerId: 17082
    },
    {
        regionOrder: 4,
        regionId: 6196,
        offerId: 16889
    },
    {
        regionOrder: 5,
        regionId: 6196,
        offerId: 17213
    }
];
const katowiceRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 31600,
        offerId: 10869
    },
    {
        regionOrder: 2,
        regionId: 31600,
        offerId: 15173
    },
    {
        regionOrder: 3,
        regionId: 31600,
        offerId: 15622
    },
    {
        regionOrder: 4,
        regionId: 31600,
        offerId: 14724
    },
    {
        regionOrder: 5,
        regionId: 31600,
        offerId: 17121
    }
];
const krakowRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 11158,
        offerId: 15996
    },
    {
        regionOrder: 2,
        regionId: 11158,
        offerId: 15916
    },
    {
        regionOrder: 3,
        regionId: 11158,
        offerId: 17289
    },
    {
        regionOrder: 4,
        regionId: 11158,
        offerId: 16469
    },
    {
        regionOrder: 5,
        regionId: 11158,
        offerId: 16491
    }
];
const kielceRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 36190,
        offerId: 17397
    },
    {
        regionOrder: 2,
        regionId: 36190,
        offerId: 17736
    },
    {
        regionOrder: 3,
        regionId: 36190,
        offerId: 15787
    },
    {
        regionOrder: 4,
        regionId: 36190,
        offerId: 16019
    },
    {
        regionOrder: 5,
        regionId: 36190,
        offerId: 16845
    }
];
const lodzRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 16187,
        offerId: 16262
    },
    {
        regionOrder: 2,
        regionId: 16187,
        offerId: 16316
    },
    {
        regionOrder: 3,
        regionId: 16187,
        offerId: 16462
    },
    {
        regionOrder: 4,
        regionId: 16187,
        offerId: 17215
    },
    {
        regionOrder: 5,
        regionId: 16187,
        offerId: 16660
    }
];
const lublinRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 13220,
        offerId: 7140
    },
    {
        regionOrder: 2,
        regionId: 13220,
        offerId: 14467
    },
    {
        regionOrder: 3,
        regionId: 13220,
        offerId: 16963
    },
    {
        regionOrder: 4,
        regionId: 13220,
        offerId: 15090
    },
    {
        regionOrder: 5,
        regionId: 13220,
        offerId: 13823
    }
];
const olsztynRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 26804,
        offerId: 15280
    },
    {
        regionOrder: 2,
        regionId: 26804,
        offerId: 15620
    },
    {
        regionOrder: 3,
        regionId: 26804,
        offerId: 17153
    },
    {
        regionOrder: 4,
        regionId: 26804,
        offerId: 16878
    },
    {
        regionOrder: 5,
        regionId: 26804,
        offerId: 15168
    }
];
const poznanRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 30892,
        offerId: 16668
    },
    {
        regionOrder: 2,
        regionId: 30892,
        offerId: 14425
    },
    {
        regionOrder: 3,
        regionId: 30892,
        offerId: 16033
    },
    {
        regionOrder: 4,
        regionId: 30892,
        offerId: 14868
    },
    {
        regionOrder: 5,
        regionId: 30892,
        offerId: 11443
    }
];
const rzeszowRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 33659,
        offerId: 13568
    },
    {
        regionOrder: 2,
        regionId: 33659,
        offerId: 15299
    },
    {
        regionOrder: 3,
        regionId: 33659,
        offerId: 16854
    },
    {
        regionOrder: 4,
        regionId: 33659,
        offerId: 17564
    },
    {
        regionOrder: 5,
        regionId: 33659,
        offerId: 13572
    }
];
const szczecinRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 32657,
        offerId: 16826
    },
    {
        regionOrder: 2,
        regionId: 32657,
        offerId: 15149
    },
    {
        regionOrder: 3,
        regionId: 32657,
        offerId: 12694
    },
    {
        regionOrder: 4,
        regionId: 32657,
        offerId: 16758
    },
    {
        regionOrder: 5,
        regionId: 32657,
        offerId: 14646
    }
];
const torunRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 19894,
        offerId: 16414
    },
    {
        regionOrder: 2,
        regionId: 19894,
        offerId: 16263
    },
    {
        regionOrder: 3,
        regionId: 19894,
        offerId: 17336
    },
    {
        regionOrder: 4,
        regionId: 19894,
        offerId: 14189
    },
    {
        regionOrder: 5,
        regionId: 19894,
        offerId: 15725
    }
];
const warszawaRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 8647,
        offerId: 16394
    },
    {
        regionOrder: 2,
        regionId: 8647,
        offerId: 17320
    },
    {
        regionOrder: 3,
        regionId: 8647,
        offerId: 13935
    },
    {
        regionOrder: 4,
        regionId: 8647,
        offerId: 16642
    },
    {
        regionOrder: 5,
        regionId: 8647,
        offerId: 16207
    }
];
const wroclawRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 26955,
        offerId: 15325
    },
    {
        regionOrder: 2,
        regionId: 26955,
        offerId: 16727
    },
    {
        regionOrder: 3,
        regionId: 26955,
        offerId: 15982
    },
    {
        regionOrder: 4,
        regionId: 26955,
        offerId: 16537
    },
    {
        regionOrder: 5,
        regionId: 26955,
        offerId: 14688
    }
];

export const ranking2024: IRankingItem[] = [
    ...bialystokRanking,
    ...bydgoszczRanking,
    ...gdanskRanking,
    ...gdyniaRanking,
    ...katowiceRanking,
    ...kielceRanking,
    ...krakowRanking,
    ...lublinRanking,
    ...lodzRanking,
    ...olsztynRanking,
    ...poznanRanking,
    ...rzeszowRanking,
    ...szczecinRanking,
    ...torunRanking,
    ...warszawaRanking,
    ...wroclawRanking
];
import {IRankingItem} from "../types/IRankingItem";

const bialystokRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 9692,
        offerId: 16165
    },
    {
        regionOrder: 2,
        regionId: 9692,
        offerId: 16568
    },
    {
        regionOrder: 3,
        regionId: 9692,
        offerId: 17584
    },
    {
        regionOrder: 4,
        regionId: 9692,
        offerId: 16265
    },
    {
        regionOrder: 5,
        regionId: 9692,
        offerId: 13522
    }
];
const bydgoszczRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 22550,
        offerId: 14601
    },
    {
        regionOrder: 2,
        regionId: 22550,
        offerId: 13911
    },
    {
        regionOrder: 3,
        regionId: 22550,
        offerId: 14633
    },
    {
        regionOrder: 4,
        regionId: 22550,
        offerId: 15663
    },
    {
        regionOrder: 5,
        regionId: 22550,
        offerId: 12148
    }
];
const gdanskRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 46083,
        offerId: 15119
    },
    {
        regionOrder: 2,
        regionId: 46083,
        offerId: 14542
    },
    {
        regionOrder: 3,
        regionId: 46083,
        offerId: 13149
    },
    {
        regionOrder: 4,
        regionId: 46083,
        offerId: 14738
    },
    {
        regionOrder: 5,
        regionId: 46083,
        offerId: 17388
    }
];
const gdyniaRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 6196,
        offerId: 14116
    },
    {
        regionOrder: 2,
        regionId: 6196,
        offerId: 15816
    },
    {
        regionOrder: 3,
        regionId: 6196,
        offerId: 17082
    },
    {
        regionOrder: 4,
        regionId: 6196,
        offerId: 16889
    },
    {
        regionOrder: 5,
        regionId: 6196,
        offerId: 17213
    }
];
const katowiceRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 31600,
        offerId: 10869
    },
    {
        regionOrder: 2,
        regionId: 31600,
        offerId: 15173
    },
    {
        regionOrder: 3,
        regionId: 31600,
        offerId: 15622
    },
    {
        regionOrder: 4,
        regionId: 31600,
        offerId: 14724
    },
    {
        regionOrder: 5,
        regionId: 31600,
        offerId: 17121
    }
];
const krakowRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 11158,
        offerId: 15996
    },
    {
        regionOrder: 2,
        regionId: 11158,
        offerId: 15916
    },
    {
        regionOrder: 3,
        regionId: 11158,
        offerId: 17289
    },
    {
        regionOrder: 4,
        regionId: 11158,
        offerId: 16469
    },
    {
        regionOrder: 5,
        regionId: 11158,
        offerId: 16491
    }
];
const kielceRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 36190,
        offerId: 17397
    },
    {
        regionOrder: 2,
        regionId: 36190,
        offerId: 17736
    },
    {
        regionOrder: 3,
        regionId: 36190,
        offerId: 15787
    },
    {
        regionOrder: 4,
        regionId: 36190,
        offerId: 16019
    },
    {
        regionOrder: 5,
        regionId: 36190,
        offerId: 16845
    }
];
const lodzRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 16187,
        offerId: 16262
    },
    {
        regionOrder: 2,
        regionId: 16187,
        offerId: 16316
    },
    {
        regionOrder: 3,
        regionId: 16187,
        offerId: 16462
    },
    {
        regionOrder: 4,
        regionId: 16187,
        offerId: 17215
    },
    {
        regionOrder: 5,
        regionId: 16187,
        offerId: 16660
    }
];
const lublinRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 13220,
        offerId: 7140
    },
    {
        regionOrder: 2,
        regionId: 13220,
        offerId: 14467
    },
    {
        regionOrder: 3,
        regionId: 13220,
        offerId: 16963
    },
    {
        regionOrder: 4,
        regionId: 13220,
        offerId: 15090
    },
    {
        regionOrder: 5,
        regionId: 13220,
        offerId: 13823
    }
];
const olsztynRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 26804,
        offerId: 15280
    },
    {
        regionOrder: 2,
        regionId: 26804,
        offerId: 15620
    },
    {
        regionOrder: 3,
        regionId: 26804,
        offerId: 17153
    },
    {
        regionOrder: 4,
        regionId: 26804,
        offerId: 16878
    },
    {
        regionOrder: 5,
        regionId: 26804,
        offerId: 15168
    }
];
const poznanRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 30892,
        offerId: 16668
    },
    {
        regionOrder: 2,
        regionId: 30892,
        offerId: 14425
    },
    {
        regionOrder: 3,
        regionId: 30892,
        offerId: 16033
    },
    {
        regionOrder: 4,
        regionId: 30892,
        offerId: 14868
    },
    {
        regionOrder: 5,
        regionId: 30892,
        offerId: 11443
    }
];
const rzeszowRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 33659,
        offerId: 13568
    },
    {
        regionOrder: 2,
        regionId: 33659,
        offerId: 15299
    },
    {
        regionOrder: 3,
        regionId: 33659,
        offerId: 16854
    },
    {
        regionOrder: 4,
        regionId: 33659,
        offerId: 17564
    },
    {
        regionOrder: 5,
        regionId: 33659,
        offerId: 13572
    }
];
const szczecinRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 32657,
        offerId: 16826
    },
    {
        regionOrder: 2,
        regionId: 32657,
        offerId: 15149
    },
    {
        regionOrder: 3,
        regionId: 32657,
        offerId: 12694
    },
    {
        regionOrder: 4,
        regionId: 32657,
        offerId: 16758
    },
    {
        regionOrder: 5,
        regionId: 32657,
        offerId: 14646
    }
];
const torunRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 19894,
        offerId: 16414
    },
    {
        regionOrder: 2,
        regionId: 19894,
        offerId: 16263
    },
    {
        regionOrder: 3,
        regionId: 19894,
        offerId: 17336
    },
    {
        regionOrder: 4,
        regionId: 19894,
        offerId: 14189
    },
    {
        regionOrder: 5,
        regionId: 19894,
        offerId: 15725
    }
];
const warszawaRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 8647,
        offerId: 16394
    },
    {
        regionOrder: 2,
        regionId: 8647,
        offerId: 17320
    },
    {
        regionOrder: 3,
        regionId: 8647,
        offerId: 13935
    },
    {
        regionOrder: 4,
        regionId: 8647,
        offerId: 16642
    },
    {
        regionOrder: 5,
        regionId: 8647,
        offerId: 16207
    }
];
const wroclawRanking: IRankingItem[] = [
    {
        regionOrder: 1,
        regionId: 26955,
        offerId: 15325
    },
    {
        regionOrder: 2,
        regionId: 26955,
        offerId: 16727
    },
    {
        regionOrder: 3,
        regionId: 26955,
        offerId: 15982
    },
    {
        regionOrder: 4,
        regionId: 26955,
        offerId: 16537
    },
    {
        regionOrder: 5,
        regionId: 26955,
        offerId: 14688
    }
];

export const ranking2024: IRankingItem[] = [
    ...bialystokRanking,
    ...bydgoszczRanking,
    ...gdanskRanking,
    ...gdyniaRanking,
    ...katowiceRanking,
    ...kielceRanking,
    ...krakowRanking,
    ...lublinRanking,
    ...lodzRanking,
    ...olsztynRanking,
    ...poznanRanking,
    ...rzeszowRanking,
    ...szczecinRanking,
    ...torunRanking,
    ...warszawaRanking,
    ...wroclawRanking
];
