import {generalRankingKeysType, generalRankings, rankingLastYear} from "../constants/ranking";

export function getRankingItems(regionId: number, year?: string) {
    const actualYearString = year || rankingLastYear.toString();
    const rankingList = generalRankings[actualYearString as generalRankingKeysType];

    return rankingList.filter((cityRecord) => cityRecord.regionId === regionId).sort((prev, next) => prev.regionOrder - next.regionOrder);
}
import {generalRankingKeysType, generalRankings, rankingLastYear} from "../constants/ranking";

export function getRankingItems(regionId: number, year?: string) {
    const actualYearString = year || rankingLastYear.toString();
    const rankingList = generalRankings[actualYearString as generalRankingKeysType];

    return rankingList.filter((cityRecord) => cityRecord.regionId === regionId).sort((prev, next) => prev.regionOrder - next.regionOrder);
}
