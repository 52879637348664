import React, {FC} from "react";
import classNames from "classnames";

import {CloseIcon} from "@pg-design/icons-module";

import {PillSizeUnionType} from "../utils/pill_variants";

import * as styles from "./Pill.module.css";

interface IProps {
    children: React.ReactNode;
    onCloseClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    size: PillSizeUnionType;
    className?: string;
}

export const Pill: FC<IProps> = (props) => {
    const cn = classNames(props.className, styles.pillBaseStyle, props.size === "small" ? styles.pillSize__small : styles.pillSize__large);

    return (
        <div className={cn}>
            {props.children}
            {props.onCloseClick && (
                <div onClick={props.onCloseClick} className={styles.iconHolder}>
                    <CloseIcon size={props.size === "large" ? "1.4" : "1.2"} />
                </div>
            )}
        </div>
    );
};
import React, {FC} from "react";
import classNames from "classnames";

import {CloseIcon} from "@pg-design/icons-module";

import {PillSizeUnionType} from "../utils/pill_variants";

import * as styles from "./Pill.module.css";

interface IProps {
    children: React.ReactNode;
    onCloseClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    size: PillSizeUnionType;
    className?: string;
}

export const Pill: FC<IProps> = (props) => {
    const cn = classNames(props.className, styles.pillBaseStyle, props.size === "small" ? styles.pillSize__small : styles.pillSize__large);

    return (
        <div className={cn}>
            {props.children}
            {props.onCloseClick && (
                <div onClick={props.onCloseClick} className={styles.iconHolder}>
                    <CloseIcon size={props.size === "large" ? "1.4" : "1.2"} />
                </div>
            )}
        </div>
    );
};
