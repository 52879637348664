import {IRankingItem} from "../types/IRankingItem";

export const ranking2022: IRankingItem[] = [
    {
        regionId: 9692,
        regionOrder: 1,
        offerId: 14309
    },
    {
        regionId: 9692,
        regionOrder: 2,
        offerId: 13522
    },
    {
        regionId: 9692,
        regionOrder: 3,
        offerId: 11965
    },
    {
        regionId: 22550,
        regionOrder: 1,
        offerId: 13911
    },
    {
        regionId: 22550,
        regionOrder: 2,
        offerId: 13899
    },
    {
        regionId: 22550,
        regionOrder: 3,
        offerId: 13408
    },
    {
        regionId: 22550,
        regionOrder: 4,
        offerId: 14633
    },
    {
        regionId: 22550,
        regionOrder: 5,
        offerId: 12148
    },
    {
        regionId: 46083,
        regionOrder: 1,
        offerId: 13411
    },
    {
        regionId: 46083,
        regionOrder: 2,
        offerId: 13529
    },
    {
        regionId: 46083,
        regionOrder: 3,
        offerId: 13870
    },
    {
        regionId: 46083,
        regionOrder: 4,
        offerId: 14086
    },
    {
        regionId: 46083,
        regionOrder: 5,
        offerId: 14211
    },
    {
        regionId: 6196,
        regionOrder: 1,
        offerId: 14116
    },
    {
        regionId: 6196,
        regionOrder: 2,
        offerId: 10264
    },
    {
        regionId: 6196,
        regionOrder: 3,
        offerId: 9368
    },
    {
        regionId: 6196,
        regionOrder: 4,
        offerId: 14278
    },
    {
        regionId: 6196,
        regionOrder: 5,
        offerId: 14384
    },
    {
        regionId: 31825,
        regionOrder: 1,
        offerId: 13419
    },
    {
        regionId: 31825,
        regionOrder: 2,
        offerId: 11313
    },
    {
        regionId: 31825,
        regionOrder: 3,
        offerId: 13091
    },
    {
        regionId: 31825,
        regionOrder: 4,
        offerId: 14050
    },
    {
        regionId: 31825,
        regionOrder: 5,
        offerId: 9020
    },
    {
        regionId: 31600,
        regionOrder: 1,
        offerId: 11656
    },
    {
        regionId: 31600,
        regionOrder: 2,
        offerId: 11661
    },
    {
        regionId: 31600,
        regionOrder: 3,
        offerId: 12921
    },
    {
        regionId: 31600,
        regionOrder: 4,
        offerId: 14348
    },
    {
        regionId: 31600,
        regionOrder: 5,
        offerId: 14451
    },
    {
        regionId: 36190,
        regionOrder: 1,
        offerId: 12385
    },
    {
        regionId: 36190,
        regionOrder: 2,
        offerId: 13020
    },
    {
        regionId: 36190,
        regionOrder: 3,
        offerId: 12999
    },
    {
        regionId: 36190,
        regionOrder: 4,
        offerId: 12064
    },
    {
        regionId: 36190,
        regionOrder: 5,
        offerId: 14963
    },
    {
        regionId: 11158,
        regionOrder: 1,
        offerId: 9863
    },
    {
        regionId: 11158,
        regionOrder: 2,
        offerId: 14423
    },
    {
        regionId: 11158,
        regionOrder: 3,
        offerId: 14565
    },
    {
        regionId: 11158,
        regionOrder: 4,
        offerId: 14814
    },
    {
        regionId: 11158,
        regionOrder: 5,
        offerId: 14228
    },
    {
        regionId: 16187,
        regionOrder: 1,
        offerId: 12452
    },
    {
        regionId: 16187,
        regionOrder: 2,
        offerId: 9376
    },
    {
        regionId: 16187,
        regionOrder: 3,
        offerId: 13894
    },
    {
        regionId: 16187,
        regionOrder: 4,
        offerId: 14291
    },
    {
        regionId: 16187,
        regionOrder: 5,
        offerId: 14589
    },
    {
        regionId: 13220,
        regionOrder: 1,
        offerId: 13593
    },
    {
        regionId: 13220,
        regionOrder: 2,
        offerId: 13013
    },
    {
        regionId: 13220,
        regionOrder: 3,
        offerId: 14559
    },
    {
        regionId: 13220,
        regionOrder: 4,
        offerId: 14081
    },
    {
        regionId: 13220,
        regionOrder: 5,
        offerId: 12395
    },
    {
        regionId: 26804,
        regionOrder: 1,
        offerId: 13669
    },
    {
        regionId: 26804,
        regionOrder: 2,
        offerId: 14185
    },
    {
        regionId: 26804,
        regionOrder: 3,
        offerId: 13192
    },
    {
        regionId: 26804,
        regionOrder: 4,
        offerId: 12653
    },
    {
        regionId: 26804,
        regionOrder: 5,
        offerId: 14493
    },
    {
        regionId: 12168,
        regionOrder: 1,
        offerId: 12013
    },
    {
        regionId: 30892,
        regionOrder: 1,
        offerId: 11443
    },
    {
        regionId: 30892,
        regionOrder: 2,
        offerId: 13308
    },
    {
        regionId: 30892,
        regionOrder: 3,
        offerId: 14328
    },
    {
        regionId: 30892,
        regionOrder: 4,
        offerId: 13682
    },
    {
        regionId: 30892,
        regionOrder: 5,
        offerId: 10798
    },

    {
        regionId: 33659,
        regionOrder: 1,
        offerId: 13568
    },
    {
        regionId: 33659,
        regionOrder: 2,
        offerId: 14219
    },
    {
        regionId: 33659,
        regionOrder: 3,
        offerId: 13766
    },
    {
        regionId: 33659,
        regionOrder: 4,
        offerId: 13881
    },
    {
        regionId: 33659,
        regionOrder: 5,
        offerId: 12002
    },
    {
        regionId: 32657,
        regionOrder: 1,
        offerId: 12694
    },
    {
        regionId: 32657,
        regionOrder: 2,
        offerId: 13842
    },
    {
        regionId: 32657,
        regionOrder: 3,
        offerId: 12079
    },
    {
        regionId: 32657,
        regionOrder: 4,
        offerId: 13356
    },
    {
        regionId: 32657,
        regionOrder: 5,
        offerId: 12936
    },
    {
        regionId: 19894,
        regionOrder: 1,
        offerId: 14189
    },
    {
        regionId: 19894,
        regionOrder: 2,
        offerId: 14046
    },
    {
        regionId: 19894,
        regionOrder: 3,
        offerId: 11741
    },
    {
        regionId: 19894,
        regionOrder: 4,
        offerId: 12132
    },
    {
        regionId: 19894,
        regionOrder: 5,
        offerId: 13998
    },
    {
        regionOrder: 1,
        regionId: 8647,
        offerId: 14294
    },
    {
        regionOrder: 2,
        offerId: 14654,
        regionId: 8647
    },
    {
        regionOrder: 3,
        offerId: 14356,
        regionId: 8647
    },
    {
        regionOrder: 4,
        offerId: 14077,
        regionId: 8647
    },
    {
        regionOrder: 5,
        offerId: 14037,
        regionId: 8647
    },

    {
        regionOrder: 1,
        regionId: 26955,
        offerId: 15094
    },
    {
        regionOrder: 2,
        regionId: 26955,
        offerId: 14054
    },
    {
        regionOrder: 3,
        regionId: 26955,
        offerId: 15077
    },
    {
        regionOrder: 4,
        regionId: 26955,
        offerId: 14353
    },
    {
        regionOrder: 5,
        regionId: 26955,
        offerId: 13719
    }
];
import {IRankingItem} from "../types/IRankingItem";

export const ranking2022: IRankingItem[] = [
    {
        regionId: 9692,
        regionOrder: 1,
        offerId: 14309
    },
    {
        regionId: 9692,
        regionOrder: 2,
        offerId: 13522
    },
    {
        regionId: 9692,
        regionOrder: 3,
        offerId: 11965
    },
    {
        regionId: 22550,
        regionOrder: 1,
        offerId: 13911
    },
    {
        regionId: 22550,
        regionOrder: 2,
        offerId: 13899
    },
    {
        regionId: 22550,
        regionOrder: 3,
        offerId: 13408
    },
    {
        regionId: 22550,
        regionOrder: 4,
        offerId: 14633
    },
    {
        regionId: 22550,
        regionOrder: 5,
        offerId: 12148
    },
    {
        regionId: 46083,
        regionOrder: 1,
        offerId: 13411
    },
    {
        regionId: 46083,
        regionOrder: 2,
        offerId: 13529
    },
    {
        regionId: 46083,
        regionOrder: 3,
        offerId: 13870
    },
    {
        regionId: 46083,
        regionOrder: 4,
        offerId: 14086
    },
    {
        regionId: 46083,
        regionOrder: 5,
        offerId: 14211
    },
    {
        regionId: 6196,
        regionOrder: 1,
        offerId: 14116
    },
    {
        regionId: 6196,
        regionOrder: 2,
        offerId: 10264
    },
    {
        regionId: 6196,
        regionOrder: 3,
        offerId: 9368
    },
    {
        regionId: 6196,
        regionOrder: 4,
        offerId: 14278
    },
    {
        regionId: 6196,
        regionOrder: 5,
        offerId: 14384
    },
    {
        regionId: 31825,
        regionOrder: 1,
        offerId: 13419
    },
    {
        regionId: 31825,
        regionOrder: 2,
        offerId: 11313
    },
    {
        regionId: 31825,
        regionOrder: 3,
        offerId: 13091
    },
    {
        regionId: 31825,
        regionOrder: 4,
        offerId: 14050
    },
    {
        regionId: 31825,
        regionOrder: 5,
        offerId: 9020
    },
    {
        regionId: 31600,
        regionOrder: 1,
        offerId: 11656
    },
    {
        regionId: 31600,
        regionOrder: 2,
        offerId: 11661
    },
    {
        regionId: 31600,
        regionOrder: 3,
        offerId: 12921
    },
    {
        regionId: 31600,
        regionOrder: 4,
        offerId: 14348
    },
    {
        regionId: 31600,
        regionOrder: 5,
        offerId: 14451
    },
    {
        regionId: 36190,
        regionOrder: 1,
        offerId: 12385
    },
    {
        regionId: 36190,
        regionOrder: 2,
        offerId: 13020
    },
    {
        regionId: 36190,
        regionOrder: 3,
        offerId: 12999
    },
    {
        regionId: 36190,
        regionOrder: 4,
        offerId: 12064
    },
    {
        regionId: 36190,
        regionOrder: 5,
        offerId: 14963
    },
    {
        regionId: 11158,
        regionOrder: 1,
        offerId: 9863
    },
    {
        regionId: 11158,
        regionOrder: 2,
        offerId: 14423
    },
    {
        regionId: 11158,
        regionOrder: 3,
        offerId: 14565
    },
    {
        regionId: 11158,
        regionOrder: 4,
        offerId: 14814
    },
    {
        regionId: 11158,
        regionOrder: 5,
        offerId: 14228
    },
    {
        regionId: 16187,
        regionOrder: 1,
        offerId: 12452
    },
    {
        regionId: 16187,
        regionOrder: 2,
        offerId: 9376
    },
    {
        regionId: 16187,
        regionOrder: 3,
        offerId: 13894
    },
    {
        regionId: 16187,
        regionOrder: 4,
        offerId: 14291
    },
    {
        regionId: 16187,
        regionOrder: 5,
        offerId: 14589
    },
    {
        regionId: 13220,
        regionOrder: 1,
        offerId: 13593
    },
    {
        regionId: 13220,
        regionOrder: 2,
        offerId: 13013
    },
    {
        regionId: 13220,
        regionOrder: 3,
        offerId: 14559
    },
    {
        regionId: 13220,
        regionOrder: 4,
        offerId: 14081
    },
    {
        regionId: 13220,
        regionOrder: 5,
        offerId: 12395
    },
    {
        regionId: 26804,
        regionOrder: 1,
        offerId: 13669
    },
    {
        regionId: 26804,
        regionOrder: 2,
        offerId: 14185
    },
    {
        regionId: 26804,
        regionOrder: 3,
        offerId: 13192
    },
    {
        regionId: 26804,
        regionOrder: 4,
        offerId: 12653
    },
    {
        regionId: 26804,
        regionOrder: 5,
        offerId: 14493
    },
    {
        regionId: 12168,
        regionOrder: 1,
        offerId: 12013
    },
    {
        regionId: 30892,
        regionOrder: 1,
        offerId: 11443
    },
    {
        regionId: 30892,
        regionOrder: 2,
        offerId: 13308
    },
    {
        regionId: 30892,
        regionOrder: 3,
        offerId: 14328
    },
    {
        regionId: 30892,
        regionOrder: 4,
        offerId: 13682
    },
    {
        regionId: 30892,
        regionOrder: 5,
        offerId: 10798
    },

    {
        regionId: 33659,
        regionOrder: 1,
        offerId: 13568
    },
    {
        regionId: 33659,
        regionOrder: 2,
        offerId: 14219
    },
    {
        regionId: 33659,
        regionOrder: 3,
        offerId: 13766
    },
    {
        regionId: 33659,
        regionOrder: 4,
        offerId: 13881
    },
    {
        regionId: 33659,
        regionOrder: 5,
        offerId: 12002
    },
    {
        regionId: 32657,
        regionOrder: 1,
        offerId: 12694
    },
    {
        regionId: 32657,
        regionOrder: 2,
        offerId: 13842
    },
    {
        regionId: 32657,
        regionOrder: 3,
        offerId: 12079
    },
    {
        regionId: 32657,
        regionOrder: 4,
        offerId: 13356
    },
    {
        regionId: 32657,
        regionOrder: 5,
        offerId: 12936
    },
    {
        regionId: 19894,
        regionOrder: 1,
        offerId: 14189
    },
    {
        regionId: 19894,
        regionOrder: 2,
        offerId: 14046
    },
    {
        regionId: 19894,
        regionOrder: 3,
        offerId: 11741
    },
    {
        regionId: 19894,
        regionOrder: 4,
        offerId: 12132
    },
    {
        regionId: 19894,
        regionOrder: 5,
        offerId: 13998
    },
    {
        regionOrder: 1,
        regionId: 8647,
        offerId: 14294
    },
    {
        regionOrder: 2,
        offerId: 14654,
        regionId: 8647
    },
    {
        regionOrder: 3,
        offerId: 14356,
        regionId: 8647
    },
    {
        regionOrder: 4,
        offerId: 14077,
        regionId: 8647
    },
    {
        regionOrder: 5,
        offerId: 14037,
        regionId: 8647
    },

    {
        regionOrder: 1,
        regionId: 26955,
        offerId: 15094
    },
    {
        regionOrder: 2,
        regionId: 26955,
        offerId: 14054
    },
    {
        regionOrder: 3,
        regionId: 26955,
        offerId: 15077
    },
    {
        regionOrder: 4,
        regionId: 26955,
        offerId: 14353
    },
    {
        regionOrder: 5,
        regionId: 26955,
        offerId: 13719
    }
];
